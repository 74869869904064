import { useContext, useEffect, useState } from 'react';
import { TreeItem } from '../../../Common/components/MIDTree/MIDTree.types';
import { useAsyncFetchDataWithArgs } from '../../../Common/global/hooks/http/hooks';
import { useBase64Thumbnail } from '../../../Common/global/hooks/productMetadata/hooks';
import { DynamicContentProduct } from '../../../lib/interfaces/dynamicContent';
import { ProjectFolder } from '../../../lib/interfaces/forge-api';
import { getAllProducts } from '../../../lib/utils/products';
import { getProjectFolders } from '../../../lib/utils/workspace';
import DataContext from '../../context/Data/Data.context';
import { initialProductTemplate } from '../../context/Data/dataStore';
import NavigationContext from '../../context/Navigation/Navigation.context';
import { Screens } from '../../context/Navigation/navigationStore';
import { FOLDER_NODE_TYPE } from '../ProductSelectionTree/ProductSelectionTree.constants';

interface UseProductSelectionProps {
  projectId?: string;
}
interface useProductSelectionState {
  products: DynamicContentProduct[] | null;
  selectedProduct: DynamicContentProduct | undefined;
  thumbnailInBase64: string | undefined;
  thumbnailLoading: boolean;
  thumbnailError: string | undefined;
  rootFoldersTreeItems: TreeItem[];
  selectedTreeItem: TreeItem | undefined;
  handleSelectTreeItem: (selectedElement: TreeItem) => void;
  handleCustomizeClick: () => void;
}

export const useProductSelection = ({
  projectId,
}: UseProductSelectionProps): useProductSelectionState => {
  const { currentProduct, setCurrentProduct } = useContext(DataContext);
  const { setCurrentScreen } = useContext(NavigationContext);

  const [selectedTreeItem, setSelectedTreeItem] = useState<TreeItem | undefined>();

  const [productsQueryArgs, setProductsQueryArgs] = useState<string[] | undefined>();
  const {
    data: products,
    loading: _productsLoading,
    error: _productsError,
  } = useAsyncFetchDataWithArgs<DynamicContentProduct[]>(getAllProducts, productsQueryArgs);

  const [rootFoldersTreeItems, setRootFoldersTreeItems] = useState<TreeItem[]>([]);
  const [getRootProjectFoldersQueryArgs, setRootProjectFoldersQueryArgs] = useState<
    string[] | undefined
  >();
  const { data: rootProductFolders } = useAsyncFetchDataWithArgs<ProjectFolder[]>(
    getProjectFolders,
    getRootProjectFoldersQueryArgs,
  );

  const { thumbnailInBase64, thumbnailLoading, thumbnailError } = useBase64Thumbnail(
    currentProduct.tenancyId,
    currentProduct.thumbnail,
  );

  useEffect(() => {
    // Reset data & view when projectId changes
    setRootProjectFoldersQueryArgs(projectId ? [projectId] : undefined);
    setProductsQueryArgs(projectId ? [projectId] : undefined);
    setCurrentProduct(initialProductTemplate);
    setSelectedTreeItem(undefined);
  }, [projectId, setCurrentProduct]);

  useEffect(() => {
    const foldersTreeItems: TreeItem[] =
      rootProductFolders?.map((folder) => ({
        id: folder.urn,
        label: folder.title,
        value: folder.urn,
        isExpandable: true,
        children: [],
        childrenType: FOLDER_NODE_TYPE,
      })) || [];
    setRootFoldersTreeItems(foldersTreeItems);
  }, [rootProductFolders]);

  useEffect(() => {
    if (selectedTreeItem?.id) {
      const selectedProduct: DynamicContentProduct | undefined = products?.find(
        (product) => product.contentId === selectedTreeItem?.id,
      );
      if (selectedProduct) {
        setCurrentProduct(selectedProduct);
      }
    }
  }, [products, selectedTreeItem?.id, setCurrentProduct]);

  const handleSelectTreeItem = (item: TreeItem) => {
    setSelectedTreeItem(item);
  };

  const handleCustomizeClick = () => {
    setCurrentScreen(Screens.PRODUCT_CUSTOMIZATION);
  };

  return {
    products,
    selectedProduct: currentProduct,
    thumbnailInBase64,
    thumbnailLoading,
    thumbnailError,
    rootFoldersTreeItems,
    selectedTreeItem,
    handleSelectTreeItem,
    handleCustomizeClick,
  };
};

export default useProductSelection;
