export interface IProperty {
  category: string;
  displayName: string;
  id: string;
  value: string;
  label?: string;
}

export interface InventorParameter {
  name: string;
  parameterType: string;
  unitType: string;
  value: string;
  isKey: boolean;
  label?: string;
  options?: string[] | number[];
}

export interface InventorProperties {
  parameters: InventorParameter[];
  iProperties: IProperty[];
}

export const isMultivaluedInventorParameter = (p: InventorParameter): boolean => !!p.options;
export const isNumericInventorParameter = (p: InventorParameter): boolean =>
  typeof p.value === 'number';
export const isBooleanInventorParameter = (p: InventorParameter): boolean =>
  p.unitType.toLowerCase() === 'boolean';
