import { DraftTemplate, OutputType } from '../../interfaces/templates';
import { mockAccount, mockAccount2 } from './mockAccounts';
import { mockPropertiesDraft1, mockPropertiesDraft2 } from './mockParametersAndIProperties';
import { mockProject, mockProject2 } from './mockProjects';
import { thumbnails } from './mockThumbnailInBase64';

export const mockNewDraft1: DraftTemplate = {
  name: 'Wall2000',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount.id,
    name: mockAccount.name,
  },
  project: {
    id: mockProject.id,
    name: mockProject.name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:/path/to/folder',
  inventorProject: '/project1/project.ipj',
  assembly: '/project2/assembly.iam',
  thumbnail: thumbnails[0],
  iProperties: mockPropertiesDraft1.iProperties,
  parameters: mockPropertiesDraft1.parameters,
  rules: [],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
  ],
};

export const mockDraft1: DraftTemplate = {
  id: '6ca6948f-8646-449a-b1a6-b12ea1dbc990',
  name: 'Wall2000',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount.id,
    name: mockAccount.name,
  },
  project: {
    id: mockProject.id,
    name: mockProject.name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:/path/to/folder',
  inventorProject: '/project1/project.ipj',
  assembly: '/project2/assembly.iam',
  thumbnail: thumbnails[0],
  iProperties: mockPropertiesDraft1.iProperties,
  parameters: mockPropertiesDraft1.parameters,
  rules: [],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
  ],
};

export const mockNewDraft2: DraftTemplate = {
  name: 'Wall3000',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount2.id,
    name: mockAccount2.name,
  },
  project: {
    id: mockProject2.id,
    name: mockProject2.name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:/path/to/folder',
  inventorProject: '/project1/project.ipj',
  assembly: '/project2/assembly.iam',
  thumbnail: thumbnails[0],
  iProperties: mockPropertiesDraft2.iProperties,
  parameters: mockPropertiesDraft2.parameters,
  rules: [],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
  ],
};

export const mockDraft2: DraftTemplate = {
  id: '843c1a80-4706-4657-9a69-eb58dbec475f',
  name: 'Wall3000',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount2.id,
    name: mockAccount2.name,
  },
  project: {
    id: mockProject2.id,
    name: mockProject2.name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:/path/to/folder',
  inventorProject: '/project1/project.ipj',
  assembly: '/project2/assembly.iam',
  thumbnail: thumbnails[0],
  iProperties: mockPropertiesDraft2.iProperties,
  parameters: mockPropertiesDraft2.parameters,
  rules: [],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
    {
      type: OutputType.BOM,
      options: {
        modelStates: ['BIM'],
      },
    },
  ],
};

export const mockDrafts: DraftTemplate[] = [mockDraft1, mockDraft2];
