import { ICON_TYPES } from '@adsk/alloy-react';
import { TreeItem } from '../../../Common/components/MIDTree/MIDTree.types';
import { DynamicContentProduct } from '../../../lib/interfaces/dynamicContent';
import { ProjectFolder } from '../../../lib/interfaces/forge-api';
import { getProjectFolders } from '../../../lib/utils/workspace';
import { FOLDER_NODE_TYPE, PRODUCT_NODE_TYPE } from './ProductSelectionTree.constants';

export const getChildNodes =
  (projectId: string, products: DynamicContentProduct[] | null) =>
  async (selectedItem: TreeItem): Promise<TreeItem[]> => {
    // The id is the folder's urn
    const { childrenType, id } = selectedItem;
    if (childrenType === PRODUCT_NODE_TYPE) {
      if (!products) {
        return [];
      }
      const productsByFolder: TreeItem[] = filterProductsByFolder(products, id);
      return productsByFolder;
    }

    const subFolders = await getSubFolders(projectId, id);
    return subFolders;
  };

export const getSubFolders = async (
  projectId: string,
  selectedFolderUrn: string,
): Promise<TreeItem[]> => {
  const subFolders: ProjectFolder[] = await getProjectFolders(projectId, selectedFolderUrn);
  const subFoldersDropdownItems: TreeItem[] = subFolders.map((folder) => ({
    id: folder.urn,
    label: folder.title,
    value: folder.urn,
    isExpandable: true,
    childrenType: folder.hasSubfolders ? FOLDER_NODE_TYPE : PRODUCT_NODE_TYPE,
    children: [],
  }));
  return subFoldersDropdownItems;
};

export const filterProductsByFolder = (
  products: DynamicContentProduct[],
  parentFolderUrn: string,
): TreeItem[] => {
  const productsDropdownItems: TreeItem[] = products
    .filter((product) => product.context.workspace.folderPath.includes(parentFolderUrn))
    .map((product) => ({
      id: product.contentId as string,
      label: product.name,
      value: product.name,
      children: [],
      iconType: ICON_TYPES.PHOTO,
    }));
  return productsDropdownItems;
};
