import alloyTheme from '@adsk/alloy-react-theme';

const screenSizes = {
  tablet: '768px',
  laptop: '1024px',
  desktop: '1200px',
};

const boxShadowBase = 5;
const marginBase = 10;
const paddingBase = 10;

const mainMargin = marginBase * 2;
const headersHeight = 60; // Header and subheader combined
const footerHeight = 60;
const tabsHeight = 26;
const tabsVerticalMargin = marginBase * 2;

// Components
const draftHeader = 50;
const heading2 = 26;
const smallButtonHeight = 24;
const largeButtonHeight = 36;
const editInputsItemCountHeight = 48;
const wideInputControl = 300;
const narrowInputControl = 150;
const borderHeight = 1;
const draftConfigurationHeaderHeight = 50;
const midTreeWidth = 610;
const editSpreadsheetControls = 125;
const tableUploadBox = 650;

export default {
  vars: {
    boxShadowBase,
    mainMargin,
    marginBase,
    paddingBase,
    headersHeight,
    footerHeight,
    topBars: headersHeight + tabsHeight + mainMargin * 2,
    smallButtonHeight,
    largeButtonHeight,
    tabsVerticalMargin,
    heading2,
    draftHeader,
    wideInputControl,
    narrowInputControl,
    editInputsMenuHeight:
      tabsVerticalMargin * 2 +
      smallButtonHeight * 2 +
      marginBase * 3.5 +
      editInputsItemCountHeight +
      borderHeight * 2 +
      draftConfigurationHeaderHeight,
    editInputsItemCountHeight,
    editInputsContainerHeight:
      tabsVerticalMargin * 2 + heading2 + marginBase * 3 + draftConfigurationHeaderHeight,
    editInputsTableHeight:
      tabsVerticalMargin * 2 +
      largeButtonHeight +
      marginBase * 3 +
      borderHeight * 2 +
      draftConfigurationHeaderHeight,
    draftsTableHeight: tabsVerticalMargin * 2 + draftHeader + borderHeight * 2,
    outputsAccordionHeight: tabsVerticalMargin * 2 + draftHeader + borderHeight * 2,
    draftConfigurationHeaderHeight,
    midTreeWidth,
    editSpreadsheetContainerHeight:
      editSpreadsheetControls +
      marginBase * 4 +
      heading2 +
      tabsVerticalMargin * 2 +
      borderHeight * 2,
    tableUploadBox,
  },
  colors: { ...alloyTheme.colors },
  typography: { ...alloyTheme.typography },
  screenSizes,
};
