import {
  DraftTemplate,
  DraftTemplateBooleanInput,
  DraftTemplateIProperty,
  DraftTemplateMultiValueNumericInput,
  DraftTemplateMultiValueTextInput,
  DraftTemplateNumericInput,
  MetaInfoPath,
  DraftTemplateInputParameter,
  DraftTemplatePublishResult,
} from '../interfaces/templates';
import { v4 as uuid4 } from 'uuid';
import { HostApi } from '../interfaces/cefSharp';
import {
  BooleanInput,
  DynamicContentProduct,
  IPropertyInput,
  MultiValueNumericInput,
  MultiValueTextInput,
  NumericInput,
  TemplateInputType,
  TemplateRule,
} from '../interfaces/dynamicContent';
import {
  InventorParameter,
  IProperty,
  isBooleanInventorParameter,
  isMultivaluedInventorParameter,
} from '../interfaces/inventorProperties';
import { publishProductFromDraft } from './publish';
import logger from '../../Common/global/logger';
import text from '../../Common/global/text/text.json';

declare let hostApi: HostApi;

export const getDrafts = async (): Promise<DraftTemplate[]> => {
  const draftsString: string | null = await hostApi.loadDrafts();

  // if we get null back, then there are no drafts yet, so we return an empty array
  const drafts: DraftTemplate[] = draftsString !== null ? JSON.parse(draftsString) : [];
  return drafts;
};

export const saveDraft = async (draft: DraftTemplate): Promise<DraftTemplate> => {
  // read drafts from storage
  const drafts: DraftTemplate[] = await getDrafts();

  // Update lastUpdate time
  const updatedDraft: DraftTemplate = { ...draft, lastUpdated: Date.now() };
  // if draft doesn't have an id, it's a new draft,
  // so we'll need to generate one and add the new draft to the list of drafts
  if (!updatedDraft.id) {
    const draftId: string = uuid4();
    updatedDraft.id = draftId;
    drafts.push(updatedDraft);
  } else {
    const index = drafts.findIndex(({ id }) => id === draft.id);
    drafts[index] = draft;
  }

  const success: boolean = await hostApi.saveDrafts(JSON.stringify(drafts));

  // throw error if drafts couldn't be saved
  if (!success) {
    throw new Error('Error while saving drafts.');
  }

  return updatedDraft;
};

export const deleteDrafts = async (draftIds: string[]): Promise<DraftTemplate[]> => {
  const drafts: DraftTemplate[] = await getDrafts();

  const restDrafts = drafts.filter((draft) => !!draft.id && !draftIds.includes(draft.id));

  const success: boolean = await hostApi.saveDrafts(JSON.stringify(restDrafts));

  if (!success) {
    throw new Error('Error while deleting drafts.');
  }

  return restDrafts;
};

export const getThumbnailImgPath = async (iamPath: string): Promise<string | undefined> => {
  try {
    const thumbnailPath = await hostApi.getThumbnailImage(iamPath);
    return thumbnailPath;
  } catch (error) {
    logger.error(text.notificationThumbnailFailed);
  }
};

export const getTopLevelAssemblyPath = (
  draftTopLevelFolder: string,
  draftAssembly: string,
): string => {
  // The  DA4I plugin expects the assembly path to include the top-folder name,
  // e.g. "Wall w Door\\Wall w Door.iam".
  const topLevelFolderPath = draftTopLevelFolder.replace(/\//g, '\\');
  const datasetFolderName = topLevelFolderPath.substring(topLevelFolderPath.lastIndexOf('\\') + 1);

  // Rely on the fact that the draft's assembly path begins with a directory separator.
  return `${datasetFolderName}${draftAssembly.replace(/\//g, '\\')}`;
};

export const draftToDCTemplate = (
  draft: DraftTemplate,
  thumbnail: string,
  datasetUrn: string,
  engine = 'DA4I',
  engineVersion = '2023',
  workspaceLocation = 'BIMDOCS',
): DynamicContentProduct => {
  // Template Inputs
  const iProperties: IPropertyInput[] = draft.iProperties.map((iProp) => ({
    category: iProp.category,
    label: iProp.label,
    name: iProp.name,
    value: iProp.value,
    readOnly: iProp.readOnly,
    type: iProp.type,
    visible: iProp.visible,
  }));

  // rules
  const rules: TemplateRule = {};
  draft.rules.forEach((rule) => {
    rules[rule.key] = {
      code: rule.code,
      ruleLabel: rule.label,
      errorMessage: rule.errorMessage,
    };
  });

  const outputs = draft.outputs.map((output) => ({
    type: output.type,
    options: output.options,
  }));

  const dcProduct: DynamicContentProduct = {
    name: draft.name,
    schemaVersion: 1,
    dataSetLocation: datasetUrn,
    tenancyId: draft.project.id,
    thumbnail,
    context: {
      projectFile: draft.inventorProject,
      topLevelAssembly: getTopLevelAssemblyPath(draft.topLevelFolder, draft.assembly),
      engine: {
        location: engine,
        version: engineVersion,
      },
      workspace: {
        location: workspaceLocation,
        folderPath: getFullFolderPath(draft.folder),
      },
    },
    rules,
    inputs: iProperties,
    outputs,
  };

  draft.parameters.forEach((param) => {
    const inputParam = toDCTemplateInputParameter(param);

    if (inputParam) {
      dcProduct.inputs.push(inputParam);
    }
  });

  return dcProduct;
};

export const toDCTemplateInputParameter = (
  param: DraftTemplateInputParameter,
): BooleanInput | NumericInput | MultiValueNumericInput | MultiValueTextInput | undefined => {
  switch (param.type) {
    case TemplateInputType.Boolean: {
      const { name, label, readOnly, value, visible, falseLabel, trueLabel, onChange } = param;
      const input: BooleanInput = {
        type: TemplateInputType.Boolean,
        label,
        name,
        readOnly,
        value,
        visible,
        falseLabel,
        trueLabel,
        onChange,
      };

      return input;
    }
    case TemplateInputType.Numeric: {
      const { name, label, readOnly, value, visible, min, max, increment, unit, onChange } = param;
      const input: NumericInput = {
        type: TemplateInputType.Numeric,
        name,
        label,
        visible,
        readOnly,
        value,
        min,
        max,
        unit,
        increment,
        onChange,
      };

      return input;
    }
    case TemplateInputType.MultiValueText: {
      const { name, label, readOnly, visible, values, unit, value } = param;

      const input: MultiValueTextInput = {
        type: TemplateInputType.MultiValueText,
        name,
        label,
        visible,
        readOnly,
        unit,
        values,
        value,
      };
      return input;
    }

    case TemplateInputType.MultiValueNumeric: {
      const {
        name,
        label,
        readOnly,
        visible,
        values,
        unit,
        value,
        min,
        max,
        allowCustomValue,
        onChange,
      } = param;

      const input: MultiValueNumericInput = {
        type: TemplateInputType.MultiValueNumeric,
        name,
        label,
        visible,
        readOnly,
        values,
        min,
        max,
        unit,
        allowCustomValue,
        value,
        onChange,
      };

      return input;
    }
    default: {
      return undefined;
    }
  }
};

export const toDraftTemplateInputParameter = (
  param: InventorParameter,
): DraftTemplateInputParameter => {
  const type = isBooleanInventorParameter(param)
    ? TemplateInputType.Boolean
    : isMultivaluedInventorParameter(param)
    ? typeof (param.options as string[])[0] === 'number'
      ? TemplateInputType.MultiValueNumeric
      : TemplateInputType.MultiValueText
    : TemplateInputType.Numeric;

  switch (type) {
    case TemplateInputType.Boolean: {
      const input: DraftTemplateBooleanInput = {
        type,
        visible: true,
        readOnly: false,
        label: param.label ?? '',
        name: param.name,
        value: /true/i.test(param.value),
        onChange: [],
      };

      return input;
    }
    case TemplateInputType.Numeric: {
      const input: DraftTemplateNumericInput = {
        type,
        visible: true,
        readOnly: false,
        label: param.label ?? '',
        name: param.name,
        value: Number(param.value),
        unit: param.unitType,
        onChange: [],
      };

      return input;
    }
    case TemplateInputType.MultiValueNumeric: {
      const input: DraftTemplateMultiValueNumericInput = {
        type,
        visible: true,
        readOnly: false,
        label: param.label ?? '',
        name: param.name,
        values: param.options as number[],
        unit: param.unitType,
        value: Number(param.value),
        onChange: [],
        allowCustomValue: true,
      };

      return input;
    }
    case TemplateInputType.MultiValueText: {
      const input: DraftTemplateMultiValueTextInput = {
        type,
        visible: true,
        readOnly: false,
        label: param.label ?? '',
        name: param.name,
        values: param.options as string[],
        unit: param.unitType,
        value: param.value,
      };

      return input;
    }
  }
};

export const toDraftTemplateIProperty = (p: IProperty): DraftTemplateIProperty => ({
  id: p.id,
  type: TemplateInputType.IProperty,
  category: p.category,
  name: p.displayName,
  label: p.label ?? '',
  readOnly: false,
  value: p.value,
  visible: true,
});

export const getFullFolderPath = (metaInfo: MetaInfoPath): string =>
  [...(metaInfo.parentPath?.map((p) => p.id) ?? []), metaInfo.id]?.join('/');

export const getFullFolderNamedPath = (metaInfo: MetaInfoPath): string =>
  [...(metaInfo.parentPath?.map((p) => p.name) ?? []), metaInfo.name]?.join('/');

export const publishDraftTemplate = async (
  draftTemplate: DraftTemplate,
): Promise<DraftTemplatePublishResult> => await publishProductFromDraft(draftTemplate);
