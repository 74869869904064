import { Button, ILLUSTRATION_TYPES, ProgressRing } from '@adsk/alloy-react';
import React from 'react';
import { ACCDocSelection } from '../../../Common/components/ACCDocSelection/ACCDocSelection';
import { useACCDocSelection } from '../../../Common/components/ACCDocSelection/useACCDocSelection';
import MIDEmptyState from '../../../Common/components/EmptyState/MIDEmptyState';
import {
  FlexContainer,
  LocationContainer,
  LocationInformation,
} from '../../../Common/global/styles/Common/Common.styles';
import text from '../../../Common/global/text/text.json';
import { ConfirmSelectionsButton } from '../../../Inventor/components/Publishing/Publishing.styles';
import { SourceContentPreviewImage } from '../../../Inventor/components/Tabs/SourceContent/SourceContentTab.styles';
import { OutputType } from '../../../lib/interfaces/templates';
import { PreviewLoader, PreviewInProgressMessageWrap } from '../../screens/Screens.styles';
import { ProductDetails } from '../ProductDetails/ProductDetails';
import ProductSelectionTree from '../ProductSelectionTree/ProductSelectionTree';
import { ProductDetailsContainer } from './ProductSelection.styles';
import useProductSelection from './useProductSelection';

export const ProductSelection: React.FC = (): JSX.Element | null => {
  const {
    accounts,
    accountsLoading,
    projects,
    projectsLoading,
    selectedAccount,
    selectedProject,
    projectsDisabled,
    handleSelectAccount,
    handleSelectProject,
  } = useACCDocSelection();
  const {
    rootFoldersTreeItems,
    selectedTreeItem,
    products,
    selectedProduct,
    thumbnailInBase64,
    thumbnailLoading,
    thumbnailError,
    handleSelectTreeItem,
    handleCustomizeClick,
  } = useProductSelection({
    projectId: selectedProject?.id,
  });

  if (!accounts) {
    return null;
  }

  const rfaOutput = selectedProduct
    ? selectedProduct.outputs.find((output) => output.type === OutputType.RFA)
    : null;

  return (
    <LocationContainer>
      <LocationInformation>{text.revitProjectSelectionDescription}</LocationInformation>
      <FlexContainer>
        <ACCDocSelection
          accounts={accounts}
          accountsLoading={accountsLoading}
          projects={projects || []}
          projectsLoading={projectsLoading}
          selectedAccount={selectedAccount}
          selectedProject={selectedProject}
          projectsDisabled={projectsDisabled}
          handleSelectAccount={handleSelectAccount}
          handleSelectProject={handleSelectProject}
        />
        <ConfirmSelectionsButton
          variant={Button.VARIANTS.PRIMARY}
          disabled={!selectedProduct?.contentId}
          onClick={handleCustomizeClick}
        >
          {text.revitCustomizeProduct}
        </ConfirmSelectionsButton>
      </FlexContainer>
      <FlexContainer>
        <ProductSelectionTree
          products={products}
          projectId={selectedProject?.id || ''}
          rootFolders={rootFoldersTreeItems}
          onSelect={handleSelectTreeItem}
          selectedTreeElement={selectedTreeItem}
        />
        {selectedProduct?.contentId && (
          <ProductDetailsContainer>
            {thumbnailLoading ? (
              <PreviewLoader>
                <ProgressRing size={ProgressRing.SIZES.LARGE} />
                <PreviewInProgressMessageWrap>
                  {text.revitLoadingThumbnail}
                </PreviewInProgressMessageWrap>
              </PreviewLoader>
            ) : thumbnailInBase64 ? (
              <SourceContentPreviewImage
                src={`data:image/jpeg;base64,${thumbnailInBase64}`}
                alt={text.previewImageAltText}
              />
            ) : (
              <MIDEmptyState
                illustrationType={
                  thumbnailError ? ILLUSTRATION_TYPES.SYSTEM_ERROR : ILLUSTRATION_TYPES.PAGE_PHOTO
                }
                title={thumbnailError ? text.revitPreviewNotAvailable : ''}
              />
            )}
            <ProductDetails
              title={selectedProduct.name || ''}
              productCategory={rfaOutput?.options?.category || ''}
              productFamily={rfaOutput?.options?.family || ''}
              productLevelOfDetail={rfaOutput?.options?.modelStates?.[0] || ''}
            />
          </ProductDetailsContainer>
        )}
      </FlexContainer>
    </LocationContainer>
  );
};

export default ProductSelection;
