/* eslint-disable @typescript-eslint/no-empty-interface */

import { WorkBook } from 'xlsx';
import {
  BooleanInput,
  DynamicContentProduct,
  IPropertyInput,
  MultiValueNumericInput,
  MultiValueTextInput,
  NumericInput,
} from './dynamicContent';

/**
 * Interface definition for draft templates.
 */
export interface DraftTemplate {
  id?: string;
  name: string;
  lastUpdated: number;
  account: MetaInfo;
  project: MetaInfo;
  folder: MetaInfoPath;
  topLevelFolder: string;
  inventorProject: string;
  assembly: string;
  thumbnail: string;
  iProperties: DraftTemplateIProperty[];
  parameters: DraftTemplateInputParameter[];
  rules: InputRule[];
  outputs: DraftTemplateOutput[];
  table?: {
    name: string;
    data: WorkBook;
  };
}

export interface DraftTemplateReducerMap extends Omit<DraftTemplate, 'parameters' | 'iProperties'> {
  parameters: Map<string, DraftTemplateInputParameter>;
  iProperties: Map<string, DraftTemplateIProperty>;
}

export interface DraftTemplateInputProperties {
  iProperties: DraftTemplateIProperty[];
  parameters: DraftTemplateInputParameter[];
}

export type DraftTemplateInputParameter =
  | DraftTemplateBooleanInput
  | DraftTemplateNumericInput
  | DraftTemplateMultiValueNumericInput
  | DraftTemplateMultiValueTextInput;

export enum PublishStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  COMPLETE = 'complete',
  FAILURE = 'failed',
}

export interface MetaInfo {
  id: string;
  name: string;
}

export type MetaInfoPath = MetaInfo & { parentPath: MetaInfo[] }; // ParentPath is the full path to the MetaInfo.ID from Root

// Extend DC Template Param and IProperty interfaces.
// Add extra properties not required in DC Templates here.
export interface DraftTemplateIProperty extends IPropertyInput {
  id: string;
}

export interface DraftTemplateBooleanInput extends BooleanInput {}

export interface DraftTemplateNumericInput extends NumericInput {}

export interface DraftTemplateMultiValueNumericInput extends MultiValueNumericInput {}

export interface DraftTemplateMultiValueTextInput extends MultiValueTextInput {}

interface InputRule {
  key: string;
  code: string;
  label?: string;
  errorMessage?: string;
}

export enum OutputType {
  RFA = 'RFA',
  BOM = 'BOM',
}

export interface DraftTemplateOutput {
  type: OutputType;
  options?: {
    modelStates?: string[];
    category?: string;
    family?: string;
  };
}

export interface DraftTemplatePublishResult {
  status: PublishStatus;
  errorMessage?: string;
  publishedProduct?: DynamicContentProduct;
}
