import React from 'react';
import SummaryTable, {
  SummaryTableRow,
} from '../../../Common/components/SummaryTable/SummaryTable';
import { TableTitle } from '../../../Inventor/components/Publishing/Publishing.styles';
import text from '../../../Common/global/text/text.json';

interface ProductDetailsProps {
  title: string;
  productCategory: string;
  productFamily: string;
  productLevelOfDetail: string;
}

export const ProductDetails: React.FC<ProductDetailsProps> = ({
  title,
  productCategory,
  productFamily,
  productLevelOfDetail,
}): JSX.Element => {
  const productDetailsData: SummaryTableRow[] = [
    {
      title: text.revitCategoryProductDetails,
      value: productCategory,
    },
    {
      title: text.revitFamilyProductDetails,
      value: productFamily,
    },
    {
      title: text.revitLevelOfDetailProductDetails,
      value: productLevelOfDetail,
    },
  ];

  return (
    <>
      <TableTitle>{title}</TableTitle>
      <SummaryTable data={productDetailsData} />
    </>
  );
};
