import React from 'react';
import {
  CheckboxState,
  Icon,
  IconType,
  ICON_TYPES,
  ProgressRing,
  Tooltip,
} from '@adsk/alloy-react';
import { StyledBranchNode, StyledExpandedIcon, StyledLeafNode } from '../MIDTree.styles';
import { GetChildNodes, LoadState } from './AsyncMIDTreeNode.types';
import { BaseAction } from '@adsk/alloy-react-tree/es/hooks/useTreeReducer';
import { IdType } from '@adsk/alloy-react-tree/es/types';
import useAsyncMIDTreeNode from './useAsyncMIDTreeNode';
import { StyledTreeNodeIcon } from './AsyncMIDTreeNode.styles';
import text from '../../../global/text/text.json';
import { TreeItem } from '../MIDTree.types';

export interface AsyncMIDTreeNodeProps {
  id: IdType;
  role: string;
  'data-testid': string;
  'aria-label': string;
  'aria-expanded': boolean;
  'aria-selected': boolean;
  'aria-hidden': boolean;
  tabindex: number;
  show: boolean;
  label: string;
  depth: number;
  isExpanded: boolean;
  isSelected: CheckboxState | undefined;
  isExpandable: boolean;
  isHighlighted: boolean;
  iconType?: IconType;
  onExpand: ({ isExpanded, id }: { isExpanded: boolean; id: IdType }) => void;
  onSelect: ((p: { isSelected: CheckboxState; id: IdType }) => void) | undefined;
  onMouseEnter: () => void;
  selectedItem: TreeItem;
  dispatch: React.Dispatch<BaseAction<TreeItem>>;
  getChildNodes: GetChildNodes;
  removeDuplicatedChildNodes: (childNodes: TreeItem[]) => TreeItem[];
}

const AsyncMIDTreeNode: React.FC<AsyncMIDTreeNodeProps> = ({
  id,
  selectedItem,
  isExpanded,
  isExpandable,
  label,
  dispatch,
  getChildNodes,
  onExpand,
  onSelect,
  iconType,
  removeDuplicatedChildNodes,
  ...restTreeNodeProps
}) => {
  const { error, loadState, handleBranchNodeClick, handleLeafNodeClick } = useAsyncMIDTreeNode({
    id,
    selectedItem,
    isExpanded,
    dispatch,
    getChildNodes,
    onExpand,
    onSelect,
    removeDuplicatedChildNodes,
  });

  return isExpandable ? (
    <StyledBranchNode
      {...restTreeNodeProps}
      id={id}
      isExpanded={isExpanded}
      label={label}
      onClick={handleBranchNodeClick}
      isSingleSelectable
    >
      <StyledExpandedIcon isExpanded={isExpanded} onExpand={handleBranchNodeClick} />
      <StyledTreeNodeIcon type={iconType || ICON_TYPES.FOLDER_FILLED} fr={0} />
      {label}
      {error && (
        <Tooltip content={text.revitDataQueryFailed}>
          <StyledTreeNodeIcon
            data-testid="error-icon"
            type={Icon.TYPES.ALERT_WARNING}
            size={16}
            fr={0}
          />
        </Tooltip>
      )}
      {loadState === LoadState.LOADING && (
        <ProgressRing data-testid="loading-spinner" size="small" />
      )}
    </StyledBranchNode>
  ) : (
    <StyledLeafNode
      {...restTreeNodeProps}
      id={id}
      label={label}
      onClick={handleLeafNodeClick}
      isSingleSelectable
    >
      <StyledTreeNodeIcon type={iconType || ICON_TYPES.PHOTO} fr={0} />
      {label}
    </StyledLeafNode>
  );
};

export default AsyncMIDTreeNode;
