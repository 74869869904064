import { FileFilter, CompressFolderResult } from '../interfaces/fileSystem';
import { HostApi } from '../interfaces/cefSharp';
import * as b64Buffer from 'base64-arraybuffer';

declare let hostApi: HostApi;

/**
 *
 * @returns selected folder path
 */
export const selectFolder = async (topFolder?: string): Promise<string> =>
  (await hostApi.selectFolder(topFolder ?? '')) ?? '';

/**
 * @returns selected files path
 */
export const selectFile = async (
  filter: FileFilter[],
  multiSelect?: boolean,
  topFolder?: string,
): Promise<string[]> => {
  const fileFilter = JSON.stringify(filter);
  const filePaths = await hostApi.selectFile(topFolder ?? '', fileFilter, multiSelect ?? false);
  return JSON.parse(filePaths);
};

/**
 * @param filePath The path to the file on the local filesystem
 * @returns an encoded base64 string representation of the file
 */
export const fileToBase64String = async (
  filePath: string,
): Promise<{ name: string; base64: string }> => {
  if (!hostApi.fileToBase64String) {
    throw Error('fileToBase64String is not implemented in hostApi');
  }

  try {
    return JSON.parse(await hostApi.fileToBase64String(filePath));
  } catch {
    throw new Error(`Failed to read file ${filePath}`);
  }
};

export const toFileObject = async (file: string): Promise<File> => {
  const base64 = await fileToBase64String(file);

  return new File([b64Buffer.decode(base64.base64)], base64.name);
};

export const compressFolder = async (folderPath: string): Promise<string> => {
  const json = await hostApi.compressFolder(folderPath);
  const result: CompressFolderResult = JSON.parse(json);

  if (!result.success) {
    throw new Error(result.errorMessage);
  }

  return result.zipFilePath!;
};

export const urlContentToBase64String = async (
  url: string,
): Promise<{ name: string; base64: string }> => {
  if (!hostApi.downloadFileFromUrl) {
    throw Error('downloadFileFromUrl is not implemented in hostApi');
  }

  if (!hostApi.fileToBase64String) {
    throw Error('fileToBase64String is not implemented in hostApi');
  }

  try {
    const filePath = await hostApi.downloadFileFromUrl(url);
    return JSON.parse(await hostApi.fileToBase64String(filePath));
  } catch {
    throw new Error(`Failed to download file from url`);
  }
};
