import { IconType } from '@adsk/alloy-react';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { validateNumericValue } from './utils';

interface useNumericParameterCustomizationProps {
  value: number;
  unit: string;
  readOnly: boolean;
  min?: number;
  max?: number;
  increment?: number;
  onNumericValueChange: (newValue: number) => void;
}

interface useNumericParameterCustomizationState {
  numericValue?: number;
  tooltipContent?: string;
  tooltipIconType?: IconType;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const useNumericParameterCustomization = ({
  value,
  unit,
  min,
  max,
  increment,
  onNumericValueChange,
}: useNumericParameterCustomizationProps): useNumericParameterCustomizationState => {
  const [tooltipIconType, setTooltipIconType] = useState<IconType | undefined>(undefined);
  const [tooltipContent, seTooltipContent] = useState<string | undefined>(undefined);
  const [numericValue, setNumericValue] = useState<number | undefined>(value);

  const _debounceNumericValueChange = debounce((newValue: number) => {
    const validationResult = validateNumericValue(newValue, unit, {
      min,
      max,
      increment,
    });
    if (!validationResult.valid) {
      setTooltipIconType(validationResult.invalidType);
      seTooltipContent(validationResult.message);
      setNumericValue(validationResult.correctedValue);
      if (validationResult.correctedValue) {
        onNumericValueChange(validationResult.correctedValue);
      }
    } else {
      setTooltipIconType(undefined);
      seTooltipContent(undefined);
      onNumericValueChange(newValue);
    }
  }, 500);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _handleDebounceNumericValueChange = useCallback(
    _debounceNumericValueChange,
    [], // Creating it only once on mount.
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = Number(event.target.value);
    setNumericValue(currentValue);
    _handleDebounceNumericValueChange(currentValue);
  };
  // When value is update, update the local numeric value.
  useEffect(() => {
    setNumericValue(value);
  }, [value]);
  return {
    numericValue,
    tooltipContent,
    tooltipIconType,
    handleInputChange,
  };
};

export default useNumericParameterCustomization;
