import { TREE_ACTIONS } from '@adsk/alloy-react-tree';
import { BaseAction } from '@adsk/alloy-react-tree/es/hooks/useTreeReducer';
import { IdType } from '@adsk/alloy-react-tree/es/types';
import { useEffect } from 'react';
import { TreeItem } from './MIDTree.types';

interface UseMIDTreeProps {
  dispatch: (value: BaseAction<TreeItem>) => void;
  treeRootElements: TreeItem[];
  orderedIds: IdType[];
}

interface UseMIDTreeState {
  removeDuplicatedChildNodes: (childNodes: TreeItem[]) => TreeItem[];
}

const useMIDTree = ({
  dispatch,
  treeRootElements,
  orderedIds,
}: UseMIDTreeProps): UseMIDTreeState => {
  useEffect(() => {
    dispatch({
      type: TREE_ACTIONS.regenerateTree,
      payload: {
        denormalizedTree: treeRootElements,
        expandedIds: {},
      },
    });
  }, [treeRootElements, dispatch]);

  const removeDuplicatedChildNodes = (childNodes: TreeItem[]) =>
    childNodes.filter((childNode: TreeItem) => !orderedIds.includes(childNode.id));

  return {
    removeDuplicatedChildNodes,
  };
};

export default useMIDTree;
