import { IconType, ICON_TYPES } from '@adsk/alloy-react';
import logger from '../../../Common/global/logger';
import { isUndefined } from 'lodash';

export interface ValidationResult {
  valid: boolean;
  invalidType?: IconType;
  message?: string;
  correctedValue?: number;
}

interface NumericConstraints {
  min?: number;
  increment?: number;
  max?: number;
}

export const roundToNearestNumber = (
  value: number,
  { min, increment, max }: NumericConstraints,
): number => {
  if (
    (!isUndefined(max) && value > max) ||
    (!isUndefined(min) && value < min) ||
    isUndefined(increment) ||
    isUndefined(min)
  ) {
    const errorMessage = `Invalid value passed to roundedToNearestNumber().`;
    logger.error(errorMessage, { min, max, increment });
    throw new Error(errorMessage);
  }
  const modValue = (value - min) % increment;
  const nearestNumber = value - modValue + Math.round(modValue / increment) * increment;
  return !isUndefined(max) && nearestNumber > max ? nearestNumber - increment : nearestNumber;
};

export const validateNumericValue = (
  value: number,
  unit: string,
  constraints: NumericConstraints,
): ValidationResult => {
  const { min, max, increment } = constraints;
  const validateResult: ValidationResult = { valid: true };
  if (!isUndefined(max) && value > max) {
    validateResult.valid = false;
    validateResult.invalidType = ICON_TYPES.ALERT_WARNING_FILLED;
    validateResult.message = `Invalid entry: ${value}. Maximum value is ${max} ${unit}.`;
    // Need to call roundToNearestNumber() with max to make sure that max is acceptable
    validateResult.correctedValue = isUndefined(increment)
      ? max
      : roundToNearestNumber(max, constraints);
    return validateResult;
  }

  if (!isUndefined(min) && value < min) {
    validateResult.valid = false;
    validateResult.invalidType = ICON_TYPES.ALERT_WARNING_FILLED;
    validateResult.message = `Invalid entry: ${value}. Minimum value is ${min} ${unit}.`;
    // min is always acceptable
    validateResult.correctedValue = min;
    return validateResult;
  }

  if (
    !isUndefined(min) &&
    !isUndefined(increment) &&
    value >= min &&
    (value - min) % increment !== 0
  ) {
    validateResult.valid = false;
    validateResult.invalidType = ICON_TYPES.ALERT_INFORMATION_FILLED;
    validateResult.message = `Value rounded to the nearest ${increment} ${unit}.`;
    validateResult.correctedValue = roundToNearestNumber(value, constraints);
    return validateResult;
  }
  return validateResult;
};
