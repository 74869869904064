import {
  BooleanInput,
  DynamicContentInput,
  TemplateInputType,
  TemplateOutput,
} from '../../../lib/interfaces/dynamicContent';
import { OutputType } from '../../../lib/interfaces/templates';
import {
  PostVariantOutput,
  PostVariantPayload,
  PostVariantInput,
} from '../../../lib/interfaces/variants';
import { ConfigurableProductProperties } from '../../context/Data/dataStore.types';

export const transformToVariantPayload = (
  configurableProductProperties: ConfigurableProductProperties,
): PostVariantPayload => {
  const inputPayload: PostVariantInput[] = configurableProductProperties.inputs.map(
    (input: DynamicContentInput) => {
      const variantInput: PostVariantInput = {
        name: input.name,
        // TODO: The value of a DynamicContentInput should not be potentially undefined.
        // (Looks like this is still also used for draft templates mixing up separate concerns.)
        value: input.value!,
      };

      return variantInput;
    },
  );
  const outputPayload: PostVariantOutput[] = [];
  configurableProductProperties.outputs.forEach((output: TemplateOutput) => {
    if (
      output.type === OutputType.RFA &&
      typeof output.options === 'object' &&
      typeof output.options.modelStates !== 'undefined'
    ) {
      output.options.modelStates.forEach((modelState: string) => {
        outputPayload.push({
          type: output.type,
          modelState,
        });
      });
    }
  });

  const variantPaylaod: PostVariantPayload = {
    inputs: inputPayload,
    outputs: outputPayload,
  };

  return variantPaylaod;
};

export const getVisibleValueForInput = (
  input: DynamicContentInput,
  inputs: DynamicContentInput[],
): boolean => {
  const allBooleanInputs: BooleanInput[] = inputs.reduce(
    (prevInputs: BooleanInput[], booleanInput: DynamicContentInput) => {
      if (booleanInput.type === TemplateInputType.Boolean && booleanInput.name !== input.name) {
        prevInputs.push(booleanInput);
      }
      return prevInputs;
    },
    [],
  );

  const controllingInput = allBooleanInputs.find((booleanInput) =>
    booleanInput.onChange?.find(
      (dependencies) => dependencies.dependsOn.bindings.dependentValue === `${input.name}.visible`,
    ),
  );
  return controllingInput ? controllingInput.value : input.visible;
};
