import React from 'react';
import { RightButton } from '../../../Common/global/styles/Common/Common.styles';
import {
  LeftSection,
  RightSection,
  Title,
} from '../../../Common/global/styles/Header/Header.styles';
import text from '../../../Common/global/text/text.json';
import { CustomizationWrapper } from './ProductCustomization.styles';

interface ProductCustomizationHeaderState {
  isPreviewLoading: boolean;
  isInsertLoading: boolean;
  handleResetClick: () => void;
  handleUpdatePreviewClick: () => void;
}

const ProductCustomizationHeader: React.FC<ProductCustomizationHeaderState> = ({
  isPreviewLoading,
  isInsertLoading,
  handleResetClick,
  handleUpdatePreviewClick,
}) => (
  <CustomizationWrapper>
    <LeftSection>
      <Title>{text.revitCustomize}</Title>
    </LeftSection>
    <RightSection>
      <RightButton onClick={handleResetClick} disabled={isPreviewLoading || isInsertLoading}>
        {text.revitReset}
      </RightButton>
      <RightButton
        onClick={handleUpdatePreviewClick}
        disabled={isPreviewLoading || isInsertLoading}
      >
        {text.revitUpdatePreview}
      </RightButton>
    </RightSection>
  </CustomizationWrapper>
);

export default ProductCustomizationHeader;
