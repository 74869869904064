import { getAuthToken } from './auth';
import { ApiService } from '../services/api.service';
import { HostApi } from '../interfaces/cefSharp';
import axios from 'axios';
import { compressFolder, toFileObject } from './filesystem';
import {
  CompleteUploadResponse,
  UploadUrlsPayload,
  UploadUrlsResponse,
  CompleteUploadPayload,
  DownloadUrlPayload,
  DownloadUrlResponse,
} from '../interfaces/cloudStorage';
import text from '../../Common/global/text/text.json';

declare let hostApi: HostApi;

/**
 * Gets the chunk information for upload taking into account OSS/S3 limitations.
 */
const getChunks = (blob: Blob): any => {
  const MAX_PARTS = 25;
  const MIN_CHUNKSIZE = 0x500000;

  // Just use the blob size if we can upload in a single call.
  // Otherwise, make the chunk size as large as needed but not smaller than the allowed minimum.
  const chunkSize =
    blob.size <= MIN_CHUNKSIZE
      ? blob.size
      : Math.max(MIN_CHUNKSIZE, Math.ceil(blob.size / MAX_PARTS));
  const numChunks = Math.ceil(blob.size / chunkSize);

  return {
    chunkSize,
    numChunks,
  };
};

/** Gets the filename portion of a fully qualyfied file path.
 *
 * @param filePath Fully qualified path to a file.
 * @returns The filename portion of the file path.
 */
// eslint-disable-next-line
const getFileNameFromPath = (filePath: string) => filePath.replace(/^.*(\\|\/|\:)/, '');

/**
 * Data categories
 */
export enum DataCategory {
  Inputs = 'Inputs',
  Outputs = 'Outputs',
  Logs = 'Logs',
}

/**
 * Gets an array of signed URLs with which to upload an object in multiple parts.
 *
 * @param projectId The project id.
 * @param fileName The file name.
 * @param numberOfParts The number of parts.
 * @param category The data category.
 * @returns An object containing an array of signed urls, the object key and the upload key.
 */
export const getUploadUrls = async (
  projectId: string,
  fileName: string,
  numberOfParts: number,
  category: DataCategory,
): Promise<UploadUrlsResponse> => {
  const token = await getAuthToken();

  if (!token) {
    throw Error(text.unauthorizedAccessMessage);
  }

  const dcApiUrl = await hostApi.getDcApiUrl();
  const dcApiService = new ApiService(dcApiUrl, token);
  const payload: UploadUrlsPayload = {
    fileName,
    category,
    numberOfParts,
  };
  const path = `projects/${projectId}/data/uploadurls`;
  const response = await dcApiService.post(path, payload);

  return response.data;
};

/**
 * Call after all parts have been completed uploading.
 *
 * @param tenancyId The tenancy id.
 * @param objectKey The object key.
 * @param uploadKey The upload key.
 * @param fileName The filename.
 * @param fileSize The expected size in bytes; will be validated by the server.
 * @param contentType Optional content type; defaults to 'application/octet-stream'.
 */
export const completeUpload = async (
  tenancyId: string,
  objectKey: string,
  uploadKey: string,
  fileName: string,
  fileSize: number,
  contentType = 'application/octet-stream',
): Promise<CompleteUploadResponse> => {
  const token = await getAuthToken();

  if (!token) {
    throw Error(text.unauthorizedAccessMessage);
  }

  const dcApiUrl = await hostApi.getDcApiUrl();
  const dcApiService = new ApiService(dcApiUrl, token);
  const path = `projects/${tenancyId}/data/completeupload`;
  const payload: CompleteUploadPayload = {
    objectKey,
    uploadKey,
    fileName,
    fileSize,
    contentType,
  };
  const response = await dcApiService.post(path, payload);
  const responseData: CompleteUploadResponse = response.data;

  return responseData;
};

/**
 * Uploads a file to the cloud.
 *
 * @param tenancyId The tenancy id.
 * @param category The data category.
 * @param filePath The full path of the file to upload.
 * @param contentType Optional content type; defaults to 'application/octet-stream'.
 * @returns The object key with which the file can be downloaded.
 */
export const uploadFile = async (
  tenancyId: string,
  filePath: string,
  category: DataCategory,
  contentType = 'application/octet-stream',
): Promise<string> => {
  try {
    const file: File = await toFileObject(filePath);
    const fileSize = file.size;
    const fileName = getFileNameFromPath(filePath);
    const { chunkSize, numChunks } = getChunks(file);

    // get signed URLs for multi-part upload
    const uploadUrlsResponse: UploadUrlsResponse = await getUploadUrls(
      tenancyId,
      fileName,
      numChunks,
      category,
    );
    const { urls, objectKey, uploadKey } = uploadUrlsResponse;

    // upload the chunks
    const axiosInstance = axios.create({
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });

    for (let i = 0, start = 0; i < numChunks; ++i) {
      const end = Math.min(start + chunkSize, file.size);
      const data: Blob = file.slice(start, end);

      await axiosInstance.put(urls[i], data);
      start = end;
    }

    await completeUpload(tenancyId, objectKey, uploadKey, fileName, fileSize, contentType);

    return objectKey;
  } catch (exception) {
    const errorMessage =
      exception instanceof Error ? (exception as Error).message : 'Unknown error';

    throw new Error(errorMessage);
  }
};

/**
 * Uploads a folder as a zip file to the cloud.
 *
 * @param tenancyId The tenancy id.
 * @param folderPath The path to the folder to be uploaded.
 * @param category The data category.
 * @returns The object key with which the file can be downloaded.
 */
export const uploadFolderAsZipFile = async (
  tenancyId: string,
  folderPath: string,
  category: DataCategory,
): Promise<string> => {
  const zipFilePath = await compressFolder(folderPath);

  return await uploadFile(tenancyId, zipFilePath, category);
};

/**
 * Retrieves a short-lived signed URL for downloading files.
 *
 * @param tenancyId The tenancy id.
 * @param objectKey the object key.
 * @returns A signed URL with which the file can be downloaded.
 */
export const getDownloadUrl = async (tenancyId: string, objectKey: string): Promise<string> => {
  const token = await getAuthToken();

  if (!token) {
    throw Error(text.unauthorizedAccessMessage);
  }

  const dcApiUrl = await hostApi.getDcApiUrl();
  const dcApiService = new ApiService(dcApiUrl, token);
  const path = `projects/${tenancyId}/data/downloadurl`;
  const payload: DownloadUrlPayload = { objectKey };
  const response = await dcApiService.post(path, payload);
  const responseData: DownloadUrlResponse = response.data;

  return responseData.signedUrl;
};
