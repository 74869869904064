import styled from 'styled-components';

export const SubTitle = styled.h5`
  ${({ theme }) => theme.typography.bodyMedium}
  margin: 0;
  padding: 0 0 0 ${({ theme }) => `${theme.vars.paddingBase}px`};
`;

export const TreeEmptyStateWrapper = styled.div`
  width: ${({ theme }) => `${theme.vars.midTreeWidth}px`};
  border: 1px solid ${({ theme }) => theme.colors.charcoal300};
  margin: ${({ theme }) => `${theme.vars.marginBase}px`};
`;
