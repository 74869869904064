import { TextInput } from '@adsk/alloy-react';
import React, { useContext } from 'react';
import { MIDEmptyState } from '../../../Common/components/EmptyState/MIDEmptyState';
import MultiValueList from '../../../Common/components/MultiValueList/MultiValueList';
import { RadioButtonGroup } from '../../../Common/components/RadioButtonGroup/RadioButtonGroup';
import {
  FieldSetContainer,
  FieldsetRow,
  InputFormField,
  LabelText,
  RowItem,
} from '../../../Common/global/styles/Common/Common.styles';
import { DynamicContentInput, TemplateInputType } from '../../../lib/interfaces/dynamicContent';
import DataContext from '../../context/Data/Data.context';
import NumericParameterCustomization from '../NumericParameterCustomization/NumericParameterCustomization';
import { CustomizationFormWrapper } from './ProductCustomization.styles';
import { getVisibleValueForInput } from './ProductCustomization.utils';

interface ProductCustomizationFormProps {
  inputs: DynamicContentInput[];
}

const renderInputControlByType = (
  input: DynamicContentInput,
  handleInputUpdateDataStore: (payload: DynamicContentInput) => void,
): JSX.Element => {
  switch (input.type) {
    case TemplateInputType.Numeric: {
      const handleNumericValueChange = (newValue: number) =>
        handleInputUpdateDataStore({ ...input, value: newValue });
      return (
        <NumericParameterCustomization
          value={input.value}
          unit={input.unit}
          min={input.min}
          max={input.max}
          increment={input.increment}
          readOnly={input.readOnly}
          onNumericValueChange={handleNumericValueChange}
        />
      );
    }
    case TemplateInputType.Boolean: {
      const handleRadioButtonSelectionChange = (newValue: string) =>
        handleInputUpdateDataStore({ ...input, value: newValue === 'true' });

      return (
        <RadioButtonGroup
          radioButtons={[
            { label: input.trueLabel || 'True', value: 'true' },
            { label: input.falseLabel || 'False', value: 'false' },
          ]}
          readOnly={input.readOnly}
          value={input.value.toString()}
          onRadioButtonSelectionChange={handleRadioButtonSelectionChange}
        />
      );
    }
    case TemplateInputType.MultiValueText:
    case TemplateInputType.MultiValueNumeric: {
      const handleNumericSelectionChange = (newValue: string | number) => {
        handleInputUpdateDataStore({ ...input, value: newValue as any });
      };

      return (
        <MultiValueList
          items={input.values}
          value={input.value}
          readOnly={input.readOnly}
          onSelectionChange={handleNumericSelectionChange}
        />
      );
    }
    case TemplateInputType.IProperty:
    default:
      return <TextInput readOnly={input.readOnly} value={input.value} />;
  }
};

const ProductCustomizationForm: React.FC<ProductCustomizationFormProps> = ({ inputs }) => {
  const { updateConfigurableProductInput } = useContext(DataContext);

  const handleInputUpdateDataStore = (input: DynamicContentInput) => {
    updateConfigurableProductInput(input);
  };

  const getInputLabelAndValueRange = (input: DynamicContentInput) => {
    if (
      input.type === TemplateInputType.Numeric ||
      input.type === TemplateInputType.MultiValueNumeric
    ) {
      const label = `${input.label || input.name} (${input.unit})`;
      const valueRange =
        // Because 0 evaluates to false
        (input.min || input.min === 0) && input.max
          ? input.type === TemplateInputType.Numeric && input.increment
            ? `${input.min} to ${input.max} ${input.unit} – increments of ${input.increment} ${input.unit}.`
            : `${input.min} to ${input.max} ${input.unit}.`
          : '';
      return { label, valueRange };
    }
    return { label: input.label || input.name, valueRange: '' };
  };

  const ProductInputsRows = inputs.map((input: DynamicContentInput) => {
    const visibleValueForInput = getVisibleValueForInput(input, inputs);
    const { label, valueRange } = getInputLabelAndValueRange(input);
    return visibleValueForInput ? (
      <FieldsetRow key={input.name}>
        <RowItem>
          <InputFormField label={label} labelVariant="top" width="100%">
            {renderInputControlByType(input, handleInputUpdateDataStore)}
            <LabelText>{valueRange}</LabelText>
          </InputFormField>
        </RowItem>
      </FieldsetRow>
    ) : null;
  });

  const CustomizationFormRows =
    ProductInputsRows.length <= 0 ? <MIDEmptyState /> : ProductInputsRows;

  return (
    <CustomizationFormWrapper>
      <FieldSetContainer>{CustomizationFormRows}</FieldSetContainer>
    </CustomizationFormWrapper>
  );
};

export default ProductCustomizationForm;
