import React, { useEffect, useContext, useState, useCallback } from 'react';
import { NOTIFICATION_STATUSES, useNotifications } from '@adsk/alloy-react';
import { useAsyncPollingWithArgs } from '../../Common/global/hooks/http/hooks';
import { PollState } from '../../Common/global/hooks/http/hooks.types';
import logger from '../../Common/global/logger';
import text from '../../Common/global/text/text.json';
import {
  DynamicContentInput,
  DynamicContentVariant,
  DynamicContentVariantOutput,
  TemplateOutput,
  VariantOutputStatus,
  VariantOutputType,
} from '../../lib/interfaces/dynamicContent';
import { postVariant, getVariantOutputs } from '../../lib/utils/variants';
import { transformToVariantPayload } from '../components/ProductCustomization/ProductCustomization.utils';
import { OutputType } from '../../lib/interfaces/templates';
import { PostVariantPayload } from '../../lib/interfaces/variants';
import { insertRFA } from '../../lib/utils/insertRFA';
import DataContext from '../context/Data/Data.context';
import { useBase64Thumbnail } from '../../Common/global/hooks/productMetadata/hooks';
import ModalContext from '../../Common/context/GlobalModal.context';
import { CustomizationModalHeader } from '../components/ProductCustomization/ProductCustomization.styles';

export const shouldVariantOutputsPollingContinue = (variant: DynamicContentVariant): boolean =>
  !variant.outputs.every((output) =>
    [
      VariantOutputStatus.SUCCESS,
      VariantOutputStatus.CANCELLED,
      VariantOutputStatus.FAILED,
    ].includes(output.status),
  );

const variantStatusPollingCallback = async (
  tenancyId?: string,
  contentId?: string,
  variantId?: string,
): Promise<DynamicContentVariantOutput[] | undefined> => {
  if (tenancyId && contentId && variantId) {
    return await getVariantOutputs(tenancyId, contentId, variantId);
  }
};

interface UseProductCustomizationScreenState {
  isPreviewLoading: boolean;
  isRFAGenerationLoading: boolean;
  shouldThumbnailBoxShowLoader: boolean;
  inputs: DynamicContentInput[];
  productRFAOutput?: TemplateOutput;
  thumbnailInBase64: string | undefined;
  thumbnailError: string | undefined;
  handleInsertRFA: () => void;
  handleUpdatePreviewClick: () => Promise<void>;
  handleResetClick: () => void;
}

enum PostVariantTrigger {
  UpdatePreview = 'updatePreview',
  InsertRfa = 'insertRfa',
}

export const useProductCustomizationScreen = (): UseProductCustomizationScreenState => {
  const { dontShowAgain, setModalState } = useContext(ModalContext);
  const { showNotification } = useNotifications();
  const { configurableProductProperties, currentProduct, resetConfigurableProductProperties } =
    useContext(DataContext);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [isRFAGenerationLoading, setIsRFAGenerationLoading] = useState(false);
  const [postVariantTrigger, setPostVariantTrigger] = useState<PostVariantTrigger>();
  const [savedVariant, setSavedVariant] = useState<DynamicContentVariant | undefined>();
  const {
    data: polledVariant,
    pollState,
    startPolling: startPollingVariantStatus,
  } = useAsyncPollingWithArgs<DynamicContentVariant>(
    async (): Promise<DynamicContentVariant | undefined> => {
      if (!savedVariant) {
        return;
      }
      const outputs = await variantStatusPollingCallback(
        savedVariant?.tenancyId,
        savedVariant?.contentId,
        savedVariant?.variantId,
      );

      const polled: DynamicContentVariant = { ...savedVariant };
      polled.outputs = outputs ?? [];

      // thumbnail
      polled.thumbnail =
        (polled.outputs &&
          polled.outputs.find((o) => o.type === VariantOutputType.THUMBNAIL)?.urn) ??
        polled.thumbnail;

      return polled;
    },
    shouldVariantOutputsPollingContinue,
  );
  const productRFAOutput = configurableProductProperties.outputs.find(
    (output: TemplateOutput) => output.type === OutputType.RFA,
  );

  const [argsForUseBase64Thumbnail, setArgsForUseBase64Thumbnail] = useState<{
    tenancyId?: string;
    thumbnail?: string;
  }>({});
  const { thumbnailInBase64, thumbnailLoading, thumbnailError } = useBase64Thumbnail(
    argsForUseBase64Thumbnail.tenancyId,
    argsForUseBase64Thumbnail.thumbnail,
  );

  useEffect(() => {
    setArgsForUseBase64Thumbnail({
      tenancyId: currentProduct.tenancyId,
      thumbnail: polledVariant?.thumbnail || savedVariant?.thumbnail || currentProduct.thumbnail,
    });
  }, [
    currentProduct.tenancyId,
    currentProduct.thumbnail,
    polledVariant?.thumbnail,
    savedVariant?.thumbnail,
  ]);

  const areVariantOutputsReady = (outputs: DynamicContentVariantOutput[]): Boolean =>
    outputs.every((output) => output.status === VariantOutputStatus.SUCCESS);

  const pollingVariantAfterUpdatePreview = useCallback(async () => {
    if (pollState === PollState.POLLING) {
      setIsPreviewLoading(true);
    }

    if (pollState === PollState.FINISHED) {
      if (polledVariant && areVariantOutputsReady(polledVariant.outputs)) {
        showNotification({
          message: text.revitSucceedUpdatePreview,
          status: NOTIFICATION_STATUSES.SUCCESS,
        });
      } else {
        showNotification({
          message: text.revitFailUpdatePreview,
          status: NOTIFICATION_STATUSES.ERROR,
        });
      }
      setIsPreviewLoading(false);
      setPostVariantTrigger(undefined);
    }

    if (pollState === PollState.ERROR) {
      setIsPreviewLoading(false);
      setPostVariantTrigger(undefined);
      showNotification({
        message: text.revitFailUpdatePreview,
        status: NOTIFICATION_STATUSES.ERROR,
      });
    }
  }, [pollState, polledVariant, showNotification]);

  const pollingVariantAfterInsertRFA = useCallback(async () => {
    if (pollState === PollState.POLLING) {
      setIsRFAGenerationLoading(true);
    }

    if (pollState === PollState.FINISHED) {
      setIsRFAGenerationLoading(false);
      setPostVariantTrigger(undefined);
      if (polledVariant) {
        // Insert RFA
        await insertRFA(polledVariant);
        showNotification({
          message: text.revitSucceedInsert,
          status: NOTIFICATION_STATUSES.SUCCESS,
        });
      } else {
        showNotification({
          message: text.revitFailInsert,
          status: NOTIFICATION_STATUSES.ERROR,
        });
      }
    }

    if (pollState === PollState.ERROR) {
      setIsRFAGenerationLoading(false);
      setPostVariantTrigger(undefined);
      showNotification({
        message: text.revitFailUpdatePreview,
        status: NOTIFICATION_STATUSES.ERROR,
      });
    }
  }, [pollState, polledVariant, showNotification]);

  useEffect(() => {
    if (postVariantTrigger) {
      if (postVariantTrigger === PostVariantTrigger.InsertRfa) {
        pollingVariantAfterInsertRFA();
      } else {
        pollingVariantAfterUpdatePreview();
      }
    }
  }, [pollingVariantAfterInsertRFA, pollingVariantAfterUpdatePreview, postVariantTrigger]);

  const postVariantAndBeginPolling = async (
    tenancyId: string,
    contentId: string,
    postVariantPayload: PostVariantPayload,
  ): Promise<void> => {
    const savedVariant: DynamicContentVariant = await postVariant(
      tenancyId,
      contentId,
      postVariantPayload,
    );
    setSavedVariant(savedVariant);
    startPollingVariantStatus();
  };

  const updateProductPreview = async () => {
    try {
      if (currentProduct.contentId) {
        setIsPreviewLoading(true);
        const postVariantPayload: PostVariantPayload = transformToVariantPayload(
          configurableProductProperties,
        );
        await postVariantAndBeginPolling(
          currentProduct.tenancyId,
          currentProduct.contentId,
          postVariantPayload,
        );
        setPostVariantTrigger(PostVariantTrigger.UpdatePreview);
      } else {
        throw new Error('Failed to update Preview. Product does not contain the contentId.');
      }
    } catch (err) {
      showNotification({
        message: text.revitFailUpdatePreview,
        status: NOTIFICATION_STATUSES.ERROR,
      });
    }
  };

  const handleUpdatePreviewClick = async () => {
    if (!dontShowAgain) {
      setModalState({
        isOpen: true,
        message: (
          <>
            <CustomizationModalHeader>{text.revitUpdatePreviewNow}</CustomizationModalHeader>
            <p>{text.revitUpdatePreviewModalMessage}</p>
          </>
        ),
        confirmButtonLabel: text.revitUpdatePreview,
        cancelButtonLabel: text.revitDontUpdate,
        dontShowAgainMessage: text.revitDontShowAgainMessage,
        onConfirmCallback: updateProductPreview,
      });
    } else {
      await updateProductPreview();
    }
  };

  const handleResetClick = (): void => {
    resetConfigurableProductProperties();
  };

  const handleInsertRFA = async () => {
    // Transform to variant payload
    const postVariantPayload = transformToVariantPayload(configurableProductProperties);
    // Post Variant
    if (currentProduct.contentId) {
      try {
        setIsRFAGenerationLoading(true);
        await postVariantAndBeginPolling(
          currentProduct.tenancyId,
          currentProduct.contentId,
          postVariantPayload,
        );
        setPostVariantTrigger(PostVariantTrigger.InsertRfa);
      } catch (err) {
        logger.error('Error: Failed to Insert variant');
        showNotification({
          message: text.revitFailInsert,
          status: NOTIFICATION_STATUSES.ERROR,
        });
      }
    } else {
      logger.error('Error: Missing contentId in selected product!', { currentProduct });
      showNotification({
        message: text.revitFailInsert,
        status: NOTIFICATION_STATUSES.ERROR,
      });
    }
  };

  return {
    isPreviewLoading,
    isRFAGenerationLoading,
    shouldThumbnailBoxShowLoader: isPreviewLoading || isRFAGenerationLoading || thumbnailLoading,
    inputs: configurableProductProperties.inputs,
    productRFAOutput,
    handleInsertRFA,
    handleUpdatePreviewClick,
    handleResetClick,
    thumbnailInBase64,
    thumbnailError,
  };
};
