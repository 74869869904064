import React from 'react';
import { MIDEmptyState } from '../../../Common/components/EmptyState/MIDEmptyState';
import MIDTree from '../../../Common/components/MIDTree/MIDTree';
import { TreeItem } from '../../../Common/components/MIDTree/MIDTree.types';
import text from '../../../Common/global/text/text.json';
import { DynamicContentProduct } from '../../../lib/interfaces/dynamicContent';
import { MetaInfo } from '../../../lib/interfaces/templates';
import { SubTitle, TreeEmptyStateWrapper } from './ProductSelectionTree.styles';
import { getChildNodes } from './ProductSelectionTree.utils';

export interface ProductSelectionTreeProps {
  projectId: string;
  products: DynamicContentProduct[] | null;
  rootFolders: TreeItem[];
  onSelect: (element: TreeItem, path: MetaInfo[]) => void;
  selectedTreeElement?: TreeItem;
}

const ProductSelectionTree: React.FC<ProductSelectionTreeProps> = ({
  projectId,
  products,
  rootFolders,
  selectedTreeElement,
  onSelect,
}) => (
  <div>
    <SubTitle>{text.productSelectTitle}</SubTitle>
    {rootFolders.length === 0 ? (
      <TreeEmptyStateWrapper>
        <MIDEmptyState />
      </TreeEmptyStateWrapper>
    ) : (
      <MIDTree
        treeRootElements={rootFolders}
        selectedTreeElement={selectedTreeElement}
        onSelect={onSelect}
        getChildNodes={getChildNodes(projectId, products)}
      />
    )}
  </div>
);
export default ProductSelectionTree;
