import { ICON_TYPES } from '@adsk/alloy-react';
import React from 'react';
import { MetaInfo } from '../../../lib/interfaces/templates';
import text from '../../global/text/text.json';
import Dropdown from '../Dropdown/Dropdown';
import { DropdownItem } from '../Dropdown/Dropdown.types';
import { DropdownField } from './ACCDocSelection.styles';

export interface ACCDocSelectionProps {
  accounts: DropdownItem[];
  accountsLoading: boolean;
  projects: DropdownItem[];
  projectsLoading: boolean;
  selectedAccount?: MetaInfo;
  selectedProject?: MetaInfo;
  projectsDisabled: boolean;
  handleSelectAccount: (changes: { selectedItem?: DropdownItem | null }) => void;
  handleSelectProject: (changes: { selectedItem?: DropdownItem | null }) => void;
}

export const ACCDocSelection: React.FC<ACCDocSelectionProps> = ({
  accounts,
  accountsLoading,
  projects,
  projectsLoading,
  selectedAccount,
  selectedProject,
  projectsDisabled,
  handleSelectAccount,
  handleSelectProject,
}): JSX.Element => (
  <>
    <DropdownField>
      <Dropdown
        elements={accounts}
        isLoadingElements={accountsLoading}
        title={text.accountsSelectDropdown}
        selectedItem={
          selectedAccount
            ? { id: selectedAccount.id, value: selectedAccount.name, label: selectedAccount.name }
            : null
        }
        onSelect={handleSelectAccount}
        menuItemIcon={ICON_TYPES.PEOPLE_FILLED}
      />
    </DropdownField>
    <DropdownField>
      <Dropdown
        elements={projects}
        isLoadingElements={projectsLoading}
        title={text.projectsSelectDropdown}
        selectedItem={
          selectedProject
            ? { id: selectedProject.id, value: selectedProject.name, label: selectedProject.name }
            : null
        }
        disabled={projectsDisabled}
        onSelect={handleSelectProject}
        menuItemIcon={ICON_TYPES.BUILDINGS_FILLED}
      />
    </DropdownField>
  </>
);
