import { useState } from 'react';
import { StoreSetterType } from '../context.types';

export enum Screens {
  PRODUCT_SELECTION = 'PRODUCT_SELECTION',
  PRODUCT_CUSTOMIZATION = 'PRODUCT_CUSTOMIZATION',
}

export interface NavigationStore {
  currentScreen: Screens;
  setCurrentScreen: StoreSetterType<Screens>;
}

export const useNavigationStore = (): NavigationStore => {
  const [currentScreen, setCurrentScreen] = useState(Screens.PRODUCT_SELECTION);

  return {
    currentScreen,
    setCurrentScreen,
  };
};
